.App {
  text-align: center;
  min-height: 100%;  /* change this to cover the full viewport height */
  display: flex;
  flex-direction: column;
}

.App-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: inherit;
}

.message {
  padding: 5px;
}

.message-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.user-message {
  align-self: flex-end;
}

.bot-message {
  text-align: left;
  margin-bottom: 10px;
}

.user-text {
  display: inline-flex;
  padding: 8px 14px;
  margin: 5px;
  border-radius: 8px;
  background-color: #66A5AD;
  color: #E0E0E0;
  max-width: 80%;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.bot-text {
  display: inline-flex;
  padding: 8px 14px;
  margin: 5px;
  border-radius: 8px;
  background-color: #9A8FBA;
  color: #E0E0E0;
  max-width: 80%;
  white-space: pre-wrap;
  word-wrap: break-word;
}

form {
  display: flex;
  align-items: center;
  justify-content: center;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
}

button {
  padding: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.chat-page-container {
  position: relative;
  z-index: 1001;
  padding-top: 60px; /* Adjust this value based on the height of your AppTitle bar */
}

.chat-page-container .MuiBox-root {
  overflow-y: auto;
  max-height: calc(100vh - 120px); /* Adjust 120px based on your layout */
}