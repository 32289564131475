html, body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  color: var(--text);
  font-family: 'Quicksand';
  background-color: var(--background);
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--background);
}
