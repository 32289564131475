.container {
  background-color: var(--background);
  padding: 80px;
  border-radius: 8px;
  color: var(--text);
  margin-top: 50px;
}

.heading {
  border-bottom: 2px solid var(--accent);
  padding-bottom: 10px;
  margin-bottom: 20px;
  color: var(--text);
}

.markdown {
  font-size: 16px;
  line-height: 1.6;
}

.markdown h1 {
  font-size: 24px;
  color: #66A5AD;
  margin-top: 30px;
  margin-bottom: 15px;
}

.markdown h2 {
  font-size: 20px;
  color: #9A8FBA;
  margin-top: 25px;
  margin-bottom: 10px;
}

.markdown p {
  margin-bottom: 15px;
}

.markdown ul, .markdown ol {
  margin-left: 20px;
  margin-bottom: 15px;
}

.markdown li {
  margin-bottom: 5px;
}

.markdown a {
  color: #FFD485;
  text-decoration: none;
}

.markdown a:hover {
  text-decoration: underline;
}
