@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;700&display=swap');

.landingpage {
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: relative; 
    color: var(--text);
    align-self: stretch;
    justify-content: center;
    overflow: hidden;
    text-align: center;
    font-size: large;
    background-color: var(--background);
}

.landingpage h1 {
    font-size: 3rem; /* Doubled from 1.5rem */
    margin-bottom: 20px;
    font-weight: 400; /* Decreased from default (which is usually 700 for h1) */
}

.landingpagechat {
    display: flex;
    flex-direction: column;
    height: 83vh;  /* Force it to occupy full viewport height, replacing fixed height */
    color: #e4e4e4;
    align-self: stretch;
    justify-content: center;
    overflow: hidden;
    text-align: center;
    font-size: xx-large;
}

.top {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.top img {
    max-width: 200px; /* Setting a maximum width for the logo */
    margin: 0 auto; /* Centering the logo horizontally */
}

.openquestionframe {
    display: flex;
    flex-direction: column;
    padding: 40px;
    padding-top: 70px;
    font-size: 20px;
    gap: 20px;
}

.googleSignInButton {
    position: relative;
    bottom: 155px;
}

.middle {
    margin-top: auto;
    display: flex;
    flex-direction: row;
    gap: 15px;
    column-gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
}

.typeYourMessage {
    flex: 1;
    position: relative;
}

.messagetextareaframe {
    align-self: center;
    border-radius: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin: auto;
    padding: 2px 12px 2px 18px;
    background-color: rgba(255, 255, 255, 0.05);
}

.messagetextareaframe .MuiInputBase-root {
    padding-left: 8px;
}

.messagetextareaframe .MuiInputBase-input.MuiInput-input.MuiInputBase-inputMultiline {
    padding: 6px 16px 6px 20px;
    font-size: 14px;
    line-height: 1.2;
    color: #E0E0E0;
}

.messagetextareaframe .MuiInputBase-input::placeholder {
    color: rgba(255, 255, 255, 0.5);
    opacity: 1;
    padding-left: 12px;
}

.messagetextareaframe .MuiIconButton-root {
    padding: 6px;
    margin-right: -6px;
}

.bottom {
    position: relative;
    bottom: 0; /* Stick to the bottom */
    width: 100%; /* Full width */
    padding: 30px; /* Padding to ensure input elements are well spaced from the edges */
    box-sizing: border-box;
}

.blurred {
    filter: blur(5px);
    transition: filter 0.3s ease-out;
}

.chatpage {
    display: flex;
    flex-direction: column;
    height: 90vh;
    position: relative;
    color: var(--text);
    align-self: stretch;
    justify-content: space-between;
    overflow: hidden;
    text-align: center;
    font-size: large;
    background-color: var(--background);
    padding-bottom: 40px;
    padding-top: 60px;
}

@media (min-width: 768px) {
    .chatpage {
        padding-top: 20px;
    }
}

.chatbottom {
    position: sticky;
    bottom: 0;
    width: 100%;
    background-color: var(--background);
}