.typing-indicator {
  display: flex;
  align-self: flex-start;
  margin: 0 15px;
  margin-bottom: 3px;
}

.typing-indicator span {
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #999;
  animation: blink 1s infinite;
}

.typing-indicator span:not(:last-child) {
  margin-right: 6px;
}

.typing-indicator span:nth-child(1) {
  animation-delay: 0.1s;
}

.typing-indicator span:nth-child(2) {
  animation-delay: 0.3s;
}

.typing-indicator span:nth-child(3) {
  animation-delay: 0.5s;
}

@keyframes blink {

  0%,
  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}